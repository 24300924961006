import store from "@/state/store";

export default [
  {
    path: "/",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: "/confirm-token",
    name: "confirm-token",
    component: () => import("../views/account/confirm-token.vue"),
    meta: {
      title: "Confirmation de l'adresse email",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () => import("../views/account/resetPassword.vue"),
    meta: {
      title: "reunitialisation du mot de passe",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: "/administration",
    meta: {
      title: "Dashboard",
    },
    component: () => import("../views/dashboard/router.vue"),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next();
      } else {
        // Continue to the login page
        next({name:'login'});
      }
    },
    children: [
      {
        path: "",
        name: "administration",
        component: () => import("../views/dashboard/index.vue"),
      },
      {
        path: "countries",
        name: "countries",
        component: () => import("../views/dashboard/country/index.vue"),
      },
      {
        path: "currencies",
        name: "currencies",
        component: () => import("../views/dashboard/currency/index.vue"),
      },
      {
        path: "abonnements.html",
        name: "subscription",
        component: () => import("../views/dashboard/pack/index.vue"),
      },
      {
        path: "clients.html",
        name: "clients",
        component: () => import("../views/dashboard/client/index.vue"),
      },
      {
        path: "clients/:id",
        name: "client",
        component: () => import("../views/dashboard/client/detail.vue"),
      },   
    ],
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
    },
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters["auth/loggedIn"]) {
        // Redirect to the home page instead
        next({ name: "administration" });
      } else {
        // Continue to the login page
        next();
      }
    },
    component: () => import("../views/auth/logout.vue")
  }
];